import {fetchWithAuth} from "../utils/Api";

export const fetchChannels = async () => {
    try {
        const response = await fetchWithAuth(`/channels`)
        return await response.json()
    } catch (error) {
        console.error('Error fetching channels :', error)
        throw error
    }
}

export const fetchChannelById = async (id) => {
    try {
        const response = await fetchWithAuth(`/channels/${id}`)
        return await response.json()
    } catch (error) {
        console.error(`Error fetching channel with ID ${id}:`, error)
        throw error
    }
}

export const createChannel = async (channelData) => {
    try {
        const response = await fetchWithAuth(`/channels`, {method: 'POST', body: JSON.stringify(channelData)})
        return await response.json()
    } catch (error) {
        console.error('Error creating connexion:', error)
        throw error
    }
}

export const updateChannel = async (id, channelData) => {
    try {
        const response = await fetchWithAuth(`/channels/${id}`, {method: 'PUT', body: JSON.stringify(channelData),})
        return await response.json()
    } catch (error) {
        console.error(`Error updating channel with ID ${id}:`, error)
        throw error
    }
}

export const deleteChannel = async (id) => {
    try {
        const response = await fetchWithAuth(`/channels`, {method: 'DELETE'})
        return await response.json()
    } catch (error) {
        console.error(`Error deleting channel with ID ${id}:`, error)
        throw error
    }
}