import React from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";

export const SideBar = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const {logout, connectedUser} = useAuth();

    return (
        <div>
            <h4>Admin</h4>
            <hr/>
            <h6>Streams</h6>
            <ul className="nav nav-pills flex-column">
                <li className="nav-item">
                    <Link className={`nav-link ${location.pathname.startsWith('/streamings') ? 'active' : ''}`}
                          aria-current="page" to="/streamings">Streamings</Link>
                </li>
            </ul>
            <hr/>
            <h6>Instances OBS</h6>
            <ul className="nav nav-pills flex-column">
                <li className="nav-item">
                    <Link className={`nav-link ${location.pathname.startsWith('/connections') ? 'active' : ''}`}
                          aria-current="page" to="/connections">Connexions OBS</Link>
                </li>
            </ul>
            <hr/>
            <h6>Platforms</h6>
            <ul className="nav nav-pills flex-column">
                <li className="nav-item">
                    <Link className={`nav-link ${location.pathname.startsWith('/platforms') ? 'active' : ''}`}
                          to="/platforms">Platforms</Link>
                </li>
                <li className="nav-item">
                    <Link className={`nav-link ${location.pathname.startsWith('/channels') ? 'active' : ''}`}
                          to="/channels">Channels</Link>
                </li>
                <li className="nav-item">
                    <Link className={`nav-link ${location.pathname.startsWith('/triggers') ? 'active' : ''}`}
                          to="/triggers">Triggers</Link>
                </li>
            </ul>
            <hr/>
            {connectedUser?.role === 'superadmin' &&
                <>
                    <h6>Users</h6>
                    <ul className="nav nav-pills flex-column">
                        <li className="nav-item">
                            <Link className={`nav-link ${location.pathname.startsWith('/users') ? 'active' : ''}`}
                                  to="/users">Users</Link>
                        </li>
                    </ul>
                    <hr/>
                </>
            }
            <button className={`btn btn-danger`} onClick={() => logout(navigate)}>Déconnexion</button>
        </div>
    )
}