import React, {useEffect} from "react";
import Toast from "bootstrap/js/dist/toast";

export const SingleToast = React.memo(({ singleToast, index }) => {
    useEffect(() => {
        const toastEl = document.getElementById(`toast-${index}`);
        const toastInstance = new Toast(toastEl);
        toastInstance.show();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleToast]);

    return (
        <div
            id={`toast-${index}`}
            className={`toast align-items-center text-bg-${singleToast.alert} border-0 fade`}
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            data-bs-autohide="true"
            data-bs-delay="5000"
        >
            <div className="d-flex">
                <div className="toast-body">
                    {singleToast.message}
                </div>
                <button
                    type="button"
                    className="btn-close btn-close-white me-2 m-auto"
                    data-bs-dismiss="toast"
                    aria-label="Close"
                ></button>
            </div>
        </div>
    );
});
