import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {formatDate} from "../../services/utils/Helper";
import {fetchStreamingById, startStreaming, stopStreaming} from "../../services/Api/StreamingApi";
import {testConnectionObs} from "../../services/Api/ConnectionOBSApi";
import {useToastStore} from "../../store/useToastStore";
import {WebSocketComponent} from "../../components/WebSocketComponent";

export const StreamingDetails = () => {
    const {id} = useParams();
    const [streaming, setStreaming] = useState(null)
    const [isStart, setIsStart] = useState(false)
    const [currentSum, setCurrentSum] = useState(0)

    const fetchStreaming = async () => {
        if (id) {
            const data = await fetchStreamingById(id)
            setStreaming(data)

            setIsStart(data.startedAt !== null)
        }
    }

    const startStream = async () => {
        const confirmed = window.confirm("Es tu certain que la synchronisation est à jour et que les parametres de scene sont les bons ?")
        if (confirmed) {
            const testConnection = await testConnectionObs(streaming.connectionOBS._id)
            if (testConnection.success) {
                const start = await startStreaming(id)
                if (start.success) {
                    await fetchStreaming()
                    useToastStore.getState().addToast('Stream démarré avec succès !', 'success')
                } else {
                    useToastStore.getState().addToast('Un problème est survenu dans le lancement du WebSocket ou du Polling', 'danger')
                }
            } else {
                useToastStore.getState().addToast('Impossible de démarrer un stream, OBS n\'est pas joignable', 'danger')
            }
        }
    }

    const stopStream = async () => {
        const confirmed = window.confirm("Es tu certain de vouloir stopper le stream?")
        if (confirmed) {
            await stopStreaming(id, currentSum)
            await fetchStreaming()
        }
    }

    useEffect(() => {
        fetchStreaming()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    return (
        <>
            {streaming &&
                <div>
                    <div className="d-flex flex-row justify-content-start align-items-center gap-2">
                        <h1>{streaming.scene.sceneName} ({streaming.connectionOBS.name})
                            -> {streaming.channel.name} ({streaming.channel.platform.name})</h1>
                        <div>
                            {streaming.startedAt === null &&
                                <button className="btn btn-success" onClick={startStream}>Start Stream</button>
                            }
                            {streaming.startedAt !== null && streaming.endedAt === null &&
                                <button className="btn btn-danger" onClick={stopStream}>Stop Stream</button>
                            }
                        </div>
                    </div>
                    <div className="d-flex flex-column align-items-start">
                        {streaming.startedAt !== null &&
                            <span className="small">Début du stream : {formatDate(streaming.startedAt)}</span>
                        }
                        {streaming.endedAt !== null &&
                            <span className="small">Fin du stream : {formatDate(streaming.endedAt)}</span>
                        }
                    </div>
                    <hr className="my-5"/>
                    <div className="row">
                        <div className={`${isStart ? "col-6 border-end" : "col-12"} overflow-auto`}
                             style={{maxHeight: '700px'}}>
                            <div className="d-flex flex-row align-items-center gap-2">
                                <h2>{streaming.scene.sceneName}</h2>
                                <Link className="btn btn-info"
                                      to={`/connections/${streaming.connectionOBS._id}`}>Configurer</Link>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Loop</th>
                                    <th>Closed</th>
                                    <th>Trigger</th>
                                    <th>Direct</th>
                                </tr>
                                </thead>
                                <tbody>
                                {streaming.scene.sources.map((source, i) => (
                                    <tr key={i}>
                                        <td>{source.sourceName}</td>
                                        <td>
                                            <input className="form-check-input" type="checkbox"
                                                   checked={source.settings.looping} disabled={true}/>
                                        </td>
                                        <td>
                                            <input className="form-check-input" type="checkbox"
                                                   checked={source.settings.close_when_inactive} disabled={true}/>
                                        </td>
                                        <td>{source.trigger ? (source.trigger.name + ' (' + source.trigger.cost + ' Tokens)') : ''}</td>
                                        <td><input className="form-check-input" type="checkbox"
                                                   checked={source.trigger?.direct} disabled={true}/></td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        {streaming && isStart &&
                            <WebSocketComponent
                                streamId={streaming._id}
                                channel={streaming.channel.name}
                                activate={streaming.startedAt !== null && streaming.endedAt === null}
                                currentSum={currentSum}
                                setCurrentSum={setCurrentSum}
                            />}
                    </div>
                </div>
            }
        </>
    )
}