import {fetchWithAuth} from "../utils/Api";

export const fetchUsers = async () => {
    try {
        const response = await fetchWithAuth(`/users`)
        return await response.json()
    } catch (error) {
        console.error('Error fetching users :', error)
        throw error
    }
}

export const fetchUserById = async (id) => {
    try {
        const response = await fetchWithAuth(`/users/${id}`)
        return await response.json()
    } catch (error) {
        console.error(`Error fetching user with ID ${id}:`, error)
        throw error
    }
}

export const createUser = async (userData) => {
    try {
        const response = await fetchWithAuth(`/users`, {method: 'POST', body: JSON.stringify(userData)})
        return await response.json()
    } catch (error) {
        console.error('Error creating connexion:', error)
        throw error
    }
}

export const createFirstSuperAdmin = async (userData) => {
    try {
        const response = await fetchWithAuth(`/users/first`, {method: 'POST', body: JSON.stringify(userData)})
        return await response.json()
    } catch (error) {
        console.error('Error creating connexion:', error)
        throw error
    }
}

export const updateUser = async (id, userData) => {
    try {
        const response = await fetchWithAuth(`/users/${id}`, {method: 'PUT', body: JSON.stringify(userData),})
        return await response.json()
    } catch (error) {
        console.error(`Error updating user with ID ${id}:`, error)
        throw error
    }
}

export const deleteUser = async (id) => {
    try {
        const response = await fetchWithAuth(`/users/${id}`, {method: 'DELETE'})
        return await response.json()
    } catch (error) {
        console.error(`Error deleting user with ID ${id}:`, error)
        throw error
    }
}