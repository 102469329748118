import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {fetchChannels, deleteChannel} from "../../services/Api/ChannelApi";

export const ChannelPage = () => {
    const [channels, setChannels] = useState([])

    const getAllChannels = async () => {
        const data = await fetchChannels()
        setChannels(data)
    };

    const removeChannel = async (id) => {
        const confirmed = window.confirm("Sure ?")
        if (confirmed) {
            await deleteChannel(id)
            const data = await fetchChannels()
            setChannels(data)
        }
    };

    useEffect(() => {
        getAllChannels()
    }, []);

    return (
        <div>
            <h1>Channels ({channels.length})</h1>
            <Link className="btn btn-success" to={`/channels/new`}>Ajouter channel</Link>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Platform</th>
                    <th>Name</th>
                    <th>Stream</th>
                    <th>Polling</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {channels && channels.length > 0 && (
                    channels.map((channel, i) => (
                        <tr key={i}>
                            <td>{channel._id}</td>
                            <td>{channel.platform.name}</td>
                            <td>{channel.name}</td>
                            <td>{channel.urlStream}</td>
                            <td>{channel.urlPolling}</td>
                            <td>
                                <div className="btn-group">
                                    <Link className="btn btn-secondary"
                                          to={`/channels/${channel._id}`}>Voir</Link>
                                    <Link className="btn btn-info"
                                          to={`/channels/${channel._id}/edit`}>Modifier</Link>
                                    <button className="btn btn-danger"
                                            onClick={() => removeChannel(channel._id)}>Supprimer
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </table>
        </div>
    )
}