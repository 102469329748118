import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Layout from "./layouts/Layout";
import {Home} from "./pages/Home";
import {ConnectionObsPage} from "./pages/ConnectionOBS/ConnectionObsPage";
import {ConnectionObsForm} from "./pages/ConnectionOBS/ConnectionObsForm";
import {ConnectionObsDetails} from "./pages/ConnectionOBS/ConnectionObsDetails";
import {PlatformPage} from "./pages/Platform/PlatformPage";
import {PlatformForm} from "./pages/Platform/PlatformForm";
import {PlatformDetails} from "./pages/Platform/PlatformDetails";
import {ChannelPage} from "./pages/Channel/ChannelPage";
import {ChannelForm} from "./pages/Channel/ChannelForm";
import {ChannelDetails} from "./pages/Channel/ChannelDetails";
import {TriggerPage} from "./pages/Trigger/TriggerPage";
import {TriggerForm} from "./pages/Trigger/TriggerForm";
import {TriggerDetails} from "./pages/Trigger/TriggerDetails";
import {StreamingPage} from "./pages/Streaming/StreamingPage";
import {StreamingForm} from "./pages/Streaming/StreamingForm";
import {StreamingDetails} from "./pages/Streaming/StreamingDetails";
import {UserPage} from "./pages/User/UserPage";
import {UserForm} from "./pages/User/UserForm";
import {UserDetails} from "./pages/User/UserDetails";
import {SuperAdminForm} from "./pages/SuperAdminForm";

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/create-superadmin" element={<SuperAdminForm/>}/>
                <Route path="/" element={<Layout/>}>
                    <Route path="connections" element={<ConnectionObsPage/>}/>
                    <Route path="connections/new" element={<ConnectionObsForm/>}/>
                    <Route path="connections/:id/edit" element={<ConnectionObsForm/>}/>
                    <Route path="connections/:id" element={<ConnectionObsDetails/>}/>
                    <Route path="platforms" element={<PlatformPage/>}/>
                    <Route path="platforms/new" element={<PlatformForm/>}/>
                    <Route path="platforms/:id/edit" element={<PlatformForm/>}/>
                    <Route path="platforms/:id" element={<PlatformDetails/>}/>
                    <Route path="channels" element={<ChannelPage/>}/>
                    <Route path="channels/new" element={<ChannelForm/>}/>
                    <Route path="channels/:id/edit" element={<ChannelForm/>}/>
                    <Route path="channels/:id" element={<ChannelDetails/>}/>
                    <Route path="triggers" element={<TriggerPage/>}/>
                    <Route path="triggers/new" element={<TriggerForm/>}/>
                    <Route path="triggers/:id/edit" element={<TriggerForm/>}/>
                    <Route path="triggers/:id" element={<TriggerDetails/>}/>
                    <Route path="streamings" element={<StreamingPage/>}/>
                    <Route path="streamings/new" element={<StreamingForm/>}/>
                    <Route path="streamings/:id/edit" element={<StreamingForm/>}/>
                    <Route path="streamings/:id" element={<StreamingDetails/>}/>
                    <Route path="users" element={<UserPage/>}/>
                    <Route path="users/new" element={<UserForm/>}/>
                    <Route path="users/:id/edit" element={<UserForm/>}/>
                    <Route path="users/:id" element={<UserDetails/>}/>
                </Route>
            </Routes>
        </Router>
    );
};

export default App;