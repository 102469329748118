import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {createUser, deleteUser, fetchUserById, updateUser} from "../../services/Api/UserApi";

export const UserForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState({
        username: '',
        password: '',
        role: 'admin'
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setCurrentUser({...currentUser, [name]: value});
    };

    const addUser = async () => {
        await createUser(currentUser);
        navigate(`/users`);
    };

    const removeUser = async () => {
        const confirmed = window.confirm("Êtes-vous sûr ?");
        if (confirmed) {
            await deleteUser(id);
            navigate(`/users`);
        }
    };

    const editUser = async (currentUser) => {
        await updateUser(id, currentUser);
        navigate(`/users`);
    };

    const fetchUser = async (id) => {
        const data = await fetchUserById(id);
        data.password = "";
        setCurrentUser(data);
    };

    useEffect(() => {
        if (id !== undefined) {
            fetchUser(id);
        } else {
            setCurrentUser({
                username: '',
                password: '',
                role: 'admin'
            });
        }
    }, [id]);

    return (
        <div>
            <h2>Formulaire d'utilisateur</h2>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="Username">Username</label>
                <div className="col-sm-10">
                    <input
                        className="form-control"
                        type="text"
                        name="username"
                        value={currentUser.username}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="password">Password</label>
                <div className="col-sm-10">
                    <input
                        className="form-control"
                        type="password"
                        name="password"
                        value={currentUser.password}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="role">Role</label>
                <div className="col-sm-10">
                    <select
                        className="form-control"
                        name="role"
                        value={currentUser.role}
                        onChange={handleInputChange}
                    >
                        <option value="">Sélectionnez un rôle</option>
                        <option value="admin">Admin</option>
                        <option value="superadmin">SuperAdmin</option>
                    </select>
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <div className="btn-group" role="group">
                    <button
                        className="btn btn-success"
                        onClick={() => id ? editUser(currentUser) : addUser()}
                    >
                        Valider
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={removeUser}
                        disabled={!currentUser._id}
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    );
};
