import {fetchWithAuth} from "../utils/Api";

export const startWS = async (id) => {
    try {
        const response = await fetchWithAuth(`/websocket/start/${id}`)
        return await response.json()
    } catch (error) {
        console.error('Error start websocket:', error)
        throw error
    }
}

export const stopWS = async (id) => {
    try {
        const response = await fetchWithAuth(`/websocket/stop/${id}`)
        return await response.json()
    } catch (error) {
        console.error('Error stop websocket:', error)
        throw error
    }
}