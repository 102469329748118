import React, {useEffect, useState} from "react";
import {fetchPlatformById} from "../../services/Api/PlatformApi";
import {useParams} from "react-router-dom";

export const PlatformDetails = () => {
    const {id} = useParams();
    const [platform, setPlatform] = useState({})

    const fetchPlatform = async () => {
        const data = await fetchPlatformById(id)
        setPlatform(data)
    }

    useEffect(() => {
        fetchPlatform();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    return (
        <div>
            <h1>Plateforme : {platform.name}</h1>
            <p>Todo : détails à venir</p>
        </div>
    )
}