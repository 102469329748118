import {fetchWithAuth} from "../utils/Api";

export const fetchConnectionOBS = async () => {
    try {
        const response = await fetchWithAuth(`/connections_obs`)
        return await response.json()
    } catch (error) {
        console.error('Error fetching connections:', error)
        throw error
    }
}

export const fetchConnectionById = async (id) => {
    try {
        const response = await fetchWithAuth(`/connections_obs/${id}`)
        return await response.json()
    } catch (error) {
        console.error(`Error fetching connection with ID ${id}:`, error)
        throw error
    }
}

export const createConnection = async (connectionData) => {
    try {
        const response = await fetchWithAuth(`/connections_obs`, {
            method: 'POST',
            body: JSON.stringify(connectionData)
        })
        return await response.json()
    } catch (error) {
        console.error('Error creating connexion:', error)
        throw error
    }
}

export const updateConnection = async (id, connectionData) => {
    try {
        const response = await fetchWithAuth(`/connections_obs/${id}`, {
            method: 'PUT',
            body: JSON.stringify(connectionData)
        })
        return await response.json()
    } catch (error) {
        console.error(`Error updating connection with ID ${id}:`, error)
        throw error
    }
}

export const deleteConnection = async (id) => {
    try {
        const response = await fetchWithAuth(`/connections_obs/${id}`, {
            method: 'DELETE',
        })
        return await response.json()
    } catch (error) {
        console.error(`Error deleting connection with ID ${id}:`, error)
        throw error
    }
}

export const synchronizeConnection = async (id) => {
    try {
        const response = await fetchWithAuth(`/connections_obs/${id}/synchro`)
        return await response.json()
    } catch (error) {
        console.error(`Error deleting connection with ID ${id}:`, error)
        throw error
    }
}

export const updateSourceSettings = async (sourceId, updatedSettings) => {
    try {
        const response = await fetchWithAuth(`/sources/${sourceId}`, {
            method: 'PUT',
            body: JSON.stringify(updatedSettings)
        })

        if (!response.ok) {
            throw new Error('Failed to update source settings');
        }

        return await response.json();
    } catch (error) {
        console.error('Error updating source settings:', error);
        throw error;
    }
};

export const testConnectionObs = async (connectionId) => {
    try {
        const response = await fetchWithAuth(`/connections_obs/${connectionId}/test`)
        return await response.json()
    } catch (error) {
        throw error
    }
}