import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {fetchChannelById} from "../../services/Api/ChannelApi";

export const ChannelDetails = () => {
    const {id} = useParams();
    const [channel, setChannel] = useState({})

    const getChannel = async () => {
        const data = await fetchChannelById(id)
        setChannel(data)
    }

    useEffect(() => {
        getChannel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div>
            <h1>Channel : {channel.name}</h1>
            <p>Todo : détails à venir</p>
        </div>
    )
}