import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {fetchUsers, deleteUser} from "../../services/Api/UserApi";

export const UserPage = () => {
    const [users, setUsers] = useState([])

    const getAllUsers = async () => {
        const data = await fetchUsers()
        setUsers(data)
    };

    const removeUser = async (id) => {
        const confirmed = window.confirm("Sure ?")
        if (confirmed) {
            await deleteUser(id)
            const data = await fetchUsers()
            setUsers(data)
        }
    };

    useEffect(() => {
        getAllUsers()
    }, []);

    return (
        <div>
            <h1>Users ({users.length})</h1>
            <Link className="btn btn-success" to={`/users/new`}>Ajouter user</Link>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Username</th>
                    <th>Role</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {users && users.length > 0 && (
                    users.map((user, i) => (
                        <tr key={i}>
                            <td>{user._id}</td>
                            <td>{user.username}</td>
                            <td>{user.role}</td>
                            <td>
                                <div className="btn-group">
                                    <Link className="btn btn-secondary"
                                          to={`/users/${user._id}`}>Voir</Link>
                                    <Link className="btn btn-info"
                                          to={`/users/${user._id}/edit`}>Modifier</Link>
                                    <button className="btn btn-danger"
                                            onClick={() => removeUser(user._id)}>Supprimer
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </table>
        </div>
    )
}