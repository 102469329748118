import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {fetchStreamings, deleteStreaming} from "../../services/Api/StreamingApi";
import {formatDate} from "../../services/utils/Helper";

export const StreamingPage = () => {
    const [streamings, setStreamings] = useState([])

    const getAllStreamings = async () => {
        const data = await fetchStreamings()
        setStreamings(data)
    };

    const removeStreaming = async (id) => {
        const confirmed = window.confirm("Sure ?")
        if (confirmed) {
            await deleteStreaming(id)
            const data = await fetchStreamings()
            setStreamings(data)
        }
    };

    useEffect(() => {
        getAllStreamings()
    }, []);

    return (
        <div>
            <h1>Streamings ({streamings.length})</h1>
            <Link className="btn btn-success" to={`/streamings/new`}>Ajouter streaming</Link>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Platform</th>
                    <th>Channel</th>
                    <th>Scene</th>
                    <th>Date Début</th>
                    <th>Date Fin</th>
                    <th>Status</th>
                    <th>Tokens</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {streamings && streamings.length > 0 && (
                    streamings.map((streaming, i) => {
                        const isWaiting = streaming.startedAt === null && streaming.endedAt === null
                        const isLive = streaming.startedAt !== null && streaming.endedAt === null
                        return (
                            <tr key={i}>
                                <td>{streaming._id}</td>
                                <td>{streaming.channel.platform.name}</td>
                                <td>{streaming.channel.name}</td>
                                <td>{streaming.scene.sceneName}</td>
                                <td>{formatDate(streaming.startedAt)}</td>
                                <td>{formatDate(streaming.endedAt)}</td>
                                <td>{isWaiting ? 'En Attente' : (isLive ? 'En Cours' : 'Terminé')}</td>
                                <td>{streaming.tokens} Tokens</td>
                                <td>
                                    <div className="btn-group">
                                        <Link className="btn btn-secondary"
                                              to={`/streamings/${streaming._id}`}>Voir</Link>
                                        {streaming.startedAt === null &&
                                            <button className="btn btn-danger"
                                                    onClick={() => removeStreaming(streaming._id)}>Supprimer
                                            </button>
                                        }
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                )}
                </tbody>
            </table>
        </div>
    )
}