import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {fetchTriggerById} from "../../services/Api/TriggerApi";

export const TriggerDetails = () => {
    const {id} = useParams();
    const [trigger, setTrigger] = useState({})

    const getTrigger = async () => {
        const data = await fetchTriggerById(id)
        setTrigger(data)
    }

    useEffect(() => {
        getTrigger()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div>
        <h1>Trigger : {trigger.name}</h1>
            <p>Todo: details à venir</p>
        </div>
    )
}