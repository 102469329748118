import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {createConnection, deleteConnection, fetchConnectionById, updateConnection} from "../../services/Api/ConnectionOBSApi";

export const ConnectionObsForm = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [currentConnexion, setCurrentConnexion] = useState({});

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setCurrentConnexion({...currentConnexion, [name]: value});
    };

    const addConnexion = async () => {
        await createConnection(currentConnexion)
        navigate(`/connections`)
    };

    const removeConnection = async () => {
        const confirmed = window.confirm("Sure ?")
        if (confirmed) {
            await deleteConnection(id)
            navigate(`/connections`)
        }
    }

    const editConnection = async (currentConnection) => {
        await updateConnection(id, currentConnection);
        navigate(`/connections`)
    };

    const fetchConnection = async (id) => {
        const data = await fetchConnectionById(id);
        setCurrentConnexion(data);
    }

    useEffect(() => {
        if (id !== undefined) {
            fetchConnection(id)
        } else {
            setCurrentConnexion({
                name: '',
                ipAddress: '',
                port: '',
                password: '',
            })
        }
    }, [id]);

    return (
        <div>
            <h2>Connexion OBS</h2>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="name">Nom de la connexion</label>
                <div className="col-sm-10">
                    <input className="form-control" type="text" name="name" value={currentConnexion.name}
                           onChange={handleInputChange}/>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="ipAddress">Adresse IP</label>
                <div className="col-sm-10">
                    <input className="form-control" type="text" name="ipAddress" value={currentConnexion.ipAddress}
                           onChange={handleInputChange}/>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="port">Port</label>
                <div className="col-sm-10">
                    <input className="form-control" type="number" name="port" value={currentConnexion.port}
                           onChange={handleInputChange}/>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="password">Password</label>
                <div className="col-sm-10">
                    <input className="form-control" type="password" name="password" value={currentConnexion.password}
                           onChange={handleInputChange}/>
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <div className="btn-group" role="group">
                    <button className="btn btn-success"
                            onClick={() => id ? editConnection(currentConnexion) : addConnexion()}>Valider
                    </button>
                    <button className="btn btn-danger" onClick={removeConnection}
                            disabled={!currentConnexion._id}>Supprimer
                    </button>
                </div>
            </div>
        </div>
    );
};
