import { create } from 'zustand'

export const useToastStore = create((set) => ({
    toasts: [],
    addToast: (message, alert = 'success') => set((state) => ({
        toasts: [...state.toasts, { message, alert }]
    })),
    removeToast: (index) => set((state) => ({
        toasts: state.toasts.filter((_, i) => i !== index)
    }))
}))