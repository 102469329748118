import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {fetchChannels} from "../../services/Api/ChannelApi";
import {fetchConnectionOBS} from "../../services/Api/ConnectionOBSApi";
import {createStreaming} from "../../services/Api/StreamingApi";
import {fetchSceneActiveByConnectionId} from "../../services/Api/SceneApi";

export const StreamingForm = () => {
    const navigate = useNavigate()
    const [channels, setChannels] = useState([])
    const [connections, setConnections] = useState([])
    const [scenes, setScenes] = useState([])
    const [selectedChannel, setSelectedChannel] = useState('')
    const [selectedConnection, setSelectedConnection] = useState('')
    const [selectedScene, setSelectedScene] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()

        const stream = {
            channel: selectedChannel,
            connectionOBS: selectedConnection,
            scene: selectedScene,
        }
        await createStreaming(stream)
        navigate('/streamings')
    }

    const fetchAllChannels = async () => {
        const data = await fetchChannels();
        setChannels(data)
    }

    const fetchAllConnections = async () => {
        const data = await fetchConnectionOBS()
        setConnections(data)
    }

    const fetchScene = async (connectionId) => {
        const data = await fetchSceneActiveByConnectionId(connectionId)
        setScenes(data)
    }

    useEffect(() => {
        fetchAllChannels()
        fetchAllConnections()
    }, [])

    useEffect(() => {
        if (selectedConnection) {
            fetchScene(selectedConnection)
        } else {
            setScenes([])
        }
    }, [selectedConnection])

    return (
        <div>
            <h2>Streamings</h2>
            <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                    <label className="col-sm-2 col-form-label" htmlFor="connection">Connexion OBS</label>
                    <div className="col-sm-10">
                        <select
                            id="connection"
                            className="form-select"
                            value={selectedConnection}
                            onChange={(e) => setSelectedConnection(e.target.value)}
                            required
                        >
                            <option value="">Sélectionnez une connexion OBS</option>
                            {connections.map(connection => (
                                <option key={connection._id} value={connection._id}>
                                    {connection.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-2 col-form-label" htmlFor="scene">scène</label>
                    <div className="col-sm-10">
                        <select
                            id="scene"
                            className="form-select"
                            value={selectedScene ? selectedScene : ''}
                            onChange={(e) => {
                                const value = e.target.value
                                if (value) {
                                    setSelectedScene(value)
                                } else {
                                    setSelectedScene('')
                                }
                            }}
                            disabled={!selectedConnection || scenes.length === 0}
                            required
                        >
                            <option value="">Sélectionnez une Scene</option>
                            {scenes?.map(scene => {
                                return (
                                    <option key={scene._id} value={scene._id}>
                                        {scene.sceneName}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-2 col-form-label" htmlFor="channel">Chaîne</label>
                    <div className="col-sm-10">
                        <select
                            id="channel"
                            className="form-select"
                            value={selectedChannel}
                            onChange={(e) => setSelectedChannel(e.target.value)}
                            required
                        >
                            <option value="">Sélectionnez une chaîne</option>
                            {channels.map(channel => (
                                <option key={channel._id} value={channel._id}>
                                    {channel.name} ({channel.platform.name})
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <button type="submit" className="btn btn-success">Créer</button>
            </form>
        </div>
    );
};
