import { useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

export const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [connectedUser, setConnectedUser] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsAuthenticated(true);
            const decoded = jwtDecode(token);
            setConnectedUser(decoded);
        } else {
            setIsAuthenticated(false);
            setConnectedUser(null);
        }
        //setIsAuthenticated(!!token); // Si un token est présent, l'utilisateur est considéré comme authentifié
    }, []);

    const login = (navigate, token) => {
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        const decoded = jwtDecode(token);
        setConnectedUser(decoded);
        navigate('/streamings'); // Redirige vers la page des plateformes après la connexion
    };

    const logout = (navigate) => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setConnectedUser(null);
        navigate('/'); // Redirige vers la page de connexion après la déconnexion
    };

    return { isAuthenticated, login, logout, connectedUser };
};