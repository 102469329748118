import {fetchWithAuth} from "../utils/Api";

export const fetchTriggers = async () => {
    try {
        const response = await fetchWithAuth(`/triggers`)
        return await response.json()
    } catch (error) {
        console.error('Error fetching triggers :', error)
        throw error
    }
}

export const fetchTriggerById = async (id) => {
    try {
        const response = await fetchWithAuth(`/triggers/${id}`)
        return await response.json()
    } catch (error) {
        console.error(`Error fetching trigger with ID ${id}:`, error)
        throw error
    }
}

export const createTrigger = async (triggerData) => {
    try {
        const response = await fetchWithAuth(`/triggers`, {
            method: 'POST',
            body: JSON.stringify(triggerData),
        })
        return await response.json()
    } catch (error) {
        console.error('Error creating connexion:', error)
        throw error
    }
}

export const updateTrigger = async (id, triggerData) => {
    try {
        const response = await fetchWithAuth(`/triggers/${id}`, {
            method: 'PUT',
            body: JSON.stringify(triggerData),
        })
        return await response.json()
    } catch (error) {
        console.error(`Error updating trigger with ID ${id}:`, error)
        throw error
    }
}

export const deleteTrigger = async (id) => {
    try {
        const response = await fetchWithAuth(`/triggers/${id}`, {method: 'DELETE'})
        return await response.json()
    } catch (error) {
        console.error(`Error deleting trigger with ID ${id}:`, error)
        throw error
    }
}