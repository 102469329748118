import React from 'react';

export const ScenesList = ({ scenes, selectedScene, onSceneSelect }) => {
    return (
        <div className="col-2 border-start">
            <h4>Scenes</h4>
            <ul className="list-group">
                {scenes.map(scene => (
                    <li
                        key={scene._id}
                        className={`list-group-item ${selectedScene && selectedScene._id === scene._id ? 'active' : ''}`}
                        onClick={() => onSceneSelect(scene)}
                        style={{ cursor: 'pointer' }}
                    >
                        {scene.sceneName} {scene.archived ? '(Archive)': ''}
                    </li>
                ))}
            </ul>
        </div>
    );
};
