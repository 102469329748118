import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {createPlatform, deletePlatform, fetchPlatformById, updatePlatform} from "../../services/Api/PlatformApi";

export const PlatformForm = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [currentPlatform, setCurrentPlatform] = useState({});

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setCurrentPlatform({...currentPlatform, [name]: value});
    };

    const addPlatform = async () => {
        await createPlatform(currentPlatform)
        navigate(`/platforms`)
    };

    const removePlatform = async () => {
        const confirmed = window.confirm("Sure ?")
        if (confirmed) {
            await deletePlatform(id)
            navigate(`/platforms`)
        }
    }

    const editPlatform = async (currentConnection) => {
        await updatePlatform(id, currentConnection);
        navigate(`/platforms`)
    };

    const fetchPlatform = async (id) => {
        const data = await fetchPlatformById(id);
        setCurrentPlatform(data);
    }

    useEffect(() => {
        if (id !== undefined) {
            fetchPlatform(id)
        } else {
            setCurrentPlatform({
                name: '',
            })
        }
    }, [id]);

    return (
        <div>
            <h2>Plateforme de streaming</h2>
            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="name">Nom de la plateforme</label>
                <div className="col-sm-10">
                    <input className="form-control" type="text" name="name" value={currentPlatform.name}
                           onChange={handleInputChange}/>
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="rtmpUrl">RTMP Url</label>
                <div className="col-sm-10">
                    <input className="form-control" type="text" name="rtmpUrl" value={currentPlatform.rtmpUrl}
                           onChange={handleInputChange}/>
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <div className="btn-group" role="group">
                    <button className="btn btn-success"
                            onClick={() => id ? editPlatform(currentPlatform) : addPlatform()}>Valider
                    </button>
                    <button className="btn btn-danger" onClick={removePlatform}
                            disabled={!currentPlatform._id}>Supprimer
                    </button>
                </div>
            </div>
        </div>
    );
};
