const {API_BASE_URL} = require("./Config");

const fetchWithAuth = async (endpoint, options = {}) => {
    const token = localStorage.getItem('token');

    // Ajouter l'en-tête Authorization si le token est présent
    const headers = {
        ...options.headers,
        'Content-Type': 'application/json',
        ...(token && { 'Authorization': `Bearer ${token}` })
    };

    // Faire la requête avec les en-têtes ajustés
    return await fetch(`${API_BASE_URL}${endpoint}`, {
        ...options,
        headers,
    });
};

module.exports = {
    fetchWithAuth,
}