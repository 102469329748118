import {fetchWithAuth} from "../utils/Api";

export const fetchPlatforms = async () => {
    try {
        const response = await fetchWithAuth(`/platforms`)
        return await response.json()
    } catch (error) {
        console.error('Error fetching platforms :', error)
        throw error
    }
}

export const fetchPlatformById = async (id) => {
    try {
        const response = await fetchWithAuth(`/platforms/${id}`)
        return await response.json()
    } catch (error) {
        console.error(`Error fetching platform with ID ${id}:`, error)
        throw error
    }
}

export const createPlatform = async (platformData) => {
    try {
        const response = await fetchWithAuth(`/platforms`, {
            method: 'POST',
            body: JSON.stringify(platformData),
        })
        return await response.json()
    } catch (error) {
        console.error('Error creating connexion:', error)
        throw error
    }
}

export const updatePlatform = async (id, platformData) => {
    try {
        const response = await fetchWithAuth(`/platforms/${id}`, {
            method: 'PUT',
            body: JSON.stringify(platformData),
        })
        return await response.json()
    } catch (error) {
        console.error(`Error updating platform with ID ${id}:`, error)
        throw error
    }
}

export const deletePlatform = async (id) => {
    try {
        const response = await fetchWithAuth(`/platforms/${id}`, {method: 'DELETE'})
        return await response.json()
    } catch (error) {
        console.error(`Error deleting platform with ID ${id}:`, error)
        throw error
    }
}