import {fetchWithAuth} from "../utils/Api";

export const getScenes = async (connexionId) => {
    try {
        const response = await fetchWithAuth(`/obs/scenes/${connexionId}`)
        return await response.json()
    } catch (error) {
        console.error('Error fetching scenes:', error)
        throw error
    }
}

export const sendToOBSWebSocket = async (sourceUuid, settings) => {
    try {
        // WebSocket API call to OBS, assumed to return a success status
        const response = await fetchWithAuth(`/obs/sources/update_settings`, {
            method: 'POST',
            body: JSON.stringify({ sourceUuid, settings }),
        })
        return await response.json();
    } catch (error) {
        console.error('Error sending to OBS WebSocket:', error);
        return { success: false };
    }
};
