import React, {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {SideBar} from "../components/SideBar";
import {Toasts} from "../components/Toasts/Toasts";
import {useAuth} from "../hooks/useAuth";

const Layout = () => {
    const {logout, connectedUser} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (connectedUser && connectedUser.exp * 1000 < Date.now()) {
            logout(navigate);
        }
    }, [connectedUser, logout, navigate]);

    return (
        <div className="d-flex" style={{height: '100vh'}}>
            <div className="bg-light border-end d-flex flex-column align-items-center col-auto"
                 style={{position: 'sticky', top: 0, height: '100vh'}}>
                <div className="p-3 w-100">
                    <SideBar/>
                </div>
            </div>

            <div className="col p-3" style={{overflowY: 'auto', height: '100vh'}}>
                <Outlet/>
            </div>
            <Toasts/>
        </div>
    );
};

export default Layout;
