import React, {useEffect, useState} from 'react'
import {fetchTriggers} from "../services/Api/TriggerApi"
import {updateSourceSettings} from "../services/Api/ConnectionOBSApi"
import {sendToOBSWebSocket} from "../services/Api/ObsApi"
import {useToastStore} from "../store/useToastStore";

export const SourcesList = ({sources, setSources, setConnection, archived}) => {
    const [triggers, setTriggers] = useState([])

    const getAllTriggers = async () => {
        const fetchedTriggers = await fetchTriggers()
        setTriggers(fetchedTriggers)
    }

    const handleSourceSettingChange = async (source, settingKey, settingValue) => {
        const previousValue = source.settings[settingKey]
        updateSourceLocally(source.sourceUuid, {[settingKey]: settingValue})
        try {
            const response = await sendToOBSWebSocket(source.sourceUuid, {
                ...source.settings,
                [settingKey]: settingValue
            })

            if (response.success) {
                await updateSourceSettings(source._id, {settings: {...source.settings, [settingKey]: settingValue}})
                updateSceneAndConnection(source._id, {settings: {...source.settings, [settingKey]: settingValue}})
                useToastStore.getState().addToast("Modification envoyée et enregistrée !", 'success')
            } else {
                useToastStore.getState().addToast("L'enregistrement de la modification à échoué, la connexion à OBS n'est pas disponible",'danger')
                updateSourceLocally(source.sourceUuid, {[settingKey]: previousValue})
            }
        } catch (error) {
            console.error('Erreur:', error)
            useToastStore.getState().addToast("L'enregistrement de la modification à échoué, la connexion à OBS n'est pas disponible",'danger')
            updateSourceLocally(source.sourceUuid, {[settingKey]: previousValue})
        }
    }


    const updateSourceLocally = (sourceUuid, updatedSettings) => {
        setSources(prevSources => prevSources.map(source => {
            if (source.sourceUuid === sourceUuid) {
                return {...source, settings: {...source.settings, ...updatedSettings}}
            }
            return source
        }))
    }

    const updateSceneAndConnection = (sourceId, updatedSettings) => {
        setSources(prevSources => {
            return prevSources?.map(source => {
                if (source._id === sourceId) {
                    return {...source, ...updatedSettings}
                }
                return source
            })
        })

        setConnection(prevConnection => {
            const updatedScenes = prevConnection.scenes.map(scene => {
                const updatedSources = scene?.sources?.map(source => {
                    if (source._id === sourceId) {
                        return {...source, ...updatedSettings}
                    }
                    return source
                })
                return {...scene, sources: updatedSources}
            })

            return {...prevConnection, scenes: updatedScenes}
        })
    }

    const handleTriggerChange = async (source, triggerId) => {
        const updatedTrigger = triggerId === '' ? null : triggerId
        await updateSourceSettings(source._id, {trigger: updatedTrigger})
        updateSceneAndConnection(source._id, {trigger: updatedTrigger})
        useToastStore.getState().addToast("Modification enregistrée !", 'success')
    }

    useEffect(() => {
        getAllTriggers()
    }, [])

    return (
        <div className="col-10">
            <h4>Sources</h4>
            <table className="table table-striped table-hover">
                <thead>
                <tr>
                    <th>Source Name</th>
                    <th>Loop</th>
                    <th>Inactive Close</th>
                    <th>Déclencheurs</th>
                </tr>
                </thead>
                <tbody>
                {sources?.map(source => (
                    <tr key={source._id}>
                        <td><h6 className="mb-0">{source.sourceName}</h6></td>
                        <td>
                            <div className="form-check form-switch">
                                <input
                                    id={`looping-${source._id}`}
                                    className="form-check-input"
                                    type="checkbox"
                                    name="looping"
                                    checked={source.settings.looping || false}
                                    onChange={(e) => handleSourceSettingChange(source, 'looping', e.target.checked)}
                                    disabled={archived}
                                />
                            </div>
                        </td>
                        <td>
                            <div className="form-check form-switch">
                                <input
                                    id={`close_when_inactive-${source._id}`}
                                    className="form-check-input"
                                    type="checkbox"
                                    name="close_when_inactive"
                                    checked={source.settings.close_when_inactive || false}
                                    onChange={(e) => handleSourceSettingChange(source, 'close_when_inactive', e.target.checked)}
                                    disabled={archived}
                                />
                            </div>
                        </td>
                        <td>
                            <select
                                id={`trigger-${source._id}`}
                                className="form-select"
                                value={source.trigger || ''}
                                onChange={(e) => handleTriggerChange(source, e.target.value)}
                                disabled={archived}
                            >
                                <option value="">Select Trigger</option>
                                {triggers.map(trigger => (
                                    <option key={trigger._id} value={trigger._id}>
                                        {`${trigger.name} (${trigger.direct ? `${trigger.cost} Tokens` : `Objectif :${trigger.cost} Tokens`})`}
                                    </option>
                                ))}
                            </select>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}