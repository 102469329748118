import {fetchWithAuth} from "../utils/Api";

export const postLogin = async (username, password) => {
    try {
        const response = await fetchWithAuth(`/login`, {
            method: "POST",
            body: JSON.stringify({ username, password }),
        })
        return await response.json()
    } catch (error) {
        console.error('Error fetching channels :', error)
        throw error
    }
}

export const checkReadyApp = async () => {
    try {
        const response = await fetchWithAuth(`/check-ready-start-app`)
        return await response.json()
    } catch (error) {
        console.error('Error fetching channels :', error)
        throw error
    }
}