import React, {useState, useEffect, useRef} from 'react'
import {startWS} from "../services/Api/WebSocketApi";
import {getEventsStreamingByStreamingId} from "../services/Api/EventStreamingApi";
const wsUrl = process.env.REACT_APP_WS_URL;

export const WebSocketComponent = ({streamId, channel, activate = false, currentSum, setCurrentSum}) => {
    const [messages, setMessages] = useState([]);
    const ws = useRef(null);

    // Fonction pour charger les événements enregistrés
    const fetchStoredEvents = async () => {
        try {
            const data = await getEventsStreamingByStreamingId(streamId)
            setMessages(data);
            const initialSum = data.reduce((acc, item) => acc + (item.event.object.tip.tokens || 0), 0);
            setCurrentSum(initialSum)
        } catch (error) {
            console.error('Error fetching stored events:', error);
        }
    };

    const startAndConnectWS = async () => {
        await startWS(streamId)
        ws.current = new WebSocket(wsUrl);

        ws.current.onopen = () => {
            console.log('WebSocket connection opened');
        };

        ws.current.onmessage = (event) => {
            const msg = JSON.parse(event.data);
            console.log(event)
            setMessages(prevMessages => [msg, ...prevMessages]);
            setCurrentSum(prevSum => prevSum + (msg.event.object.tip.tokens || 0));
        };

        ws.current.onclose = () => {
            //console.log('WebSocket connection closed');
        };

        ws.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        // Clean up the WebSocket connection when the component unmounts
        return () => {
            if (ws.current) {
                ws.current.close();
                ws.current = null;
            }
        };
    }

    useEffect(() => {
        if (streamId) {
            fetchStoredEvents();

            if (activate) {
                startAndConnectWS()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamId, activate]);

    return (
        <div className="overflow-auto col-6" style={{maxHeight: '700px'}}>
            <h2>{channel} - {currentSum} Tokens</h2>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Pseudo</th>
                    <th>Anonyme</th>
                    <th>Message</th>
                    <th>Tokens</th>
                </tr>
                </thead>
                <tbody>
                {messages.map((msg, index) => {
                    const messageDetails = msg.event || JSON.parse(msg);
                    return (
                        <tr key={index}>
                            <td>{messageDetails?.object?.user?.username}</td>
                            <td>{messageDetails?.object?.tip?.isAnon ? 'Oui' : 'Non'}</td>
                            <td>{messageDetails?.object?.tip?.message}</td>
                            <td>{messageDetails?.object?.tip?.tokens}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
