import {fetchWithAuth} from "../utils/Api";

export const getEventsStreamingByStreamingId = async (id) => {
    try {
        const response = await fetchWithAuth(`/events_streaming/streaming/${id}`)
        return await response.json()
    } catch (error) {
        console.error('Error fetching connexions:', error)
        throw error
    }
}