import React from "react";
import {useToastStore} from "../../store/useToastStore";
import {SingleToast} from "./SingleToast";

export const Toasts = React.memo(() => {
    const toasts = useToastStore((state) => state.toasts);
    return (
        <div className="toast-container position-static">
            <div className="toast-container top-0 end-0 p-3">
                {toasts && toasts.map((toast, index) => (
                    <SingleToast key={index} singleToast={toast} index={index}/>
                ))}
            </div>
        </div>
    );
}, (prevProps, nextProps) => {
    return prevProps.toasts?.length === nextProps.toasts?.length;
});