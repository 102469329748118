import {fetchWithAuth} from "../utils/Api";

export const fetchScenes = async () => {
    try {
        const response = await fetchWithAuth(`/scenes`)
        return await response.json()
    } catch (error) {
        console.error('Error fetching scenes :', error)
        throw error
    }
}

export const fetchSceneById = async (id) => {
    try {
        const response = await fetchWithAuth(`/scenes/${id}`)
        return await response.json()
    } catch (error) {
        console.error(`Error fetching scene with ID ${id}:`, error)
        throw error
    }
}

export const fetchSceneActiveByConnectionId = async (connectionId) => {
    try {
        const response = await fetchWithAuth(`/scenes/active/connections_obs/${connectionId}`)
        return await response.json()
    } catch (error) {
        console.error(`Error fetching scene with ID ${connectionId}:`, error)
        throw error
    }
}