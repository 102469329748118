import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {fetchConnectionById, synchronizeConnection} from "../../services/Api/ConnectionOBSApi";
import {ScenesList} from "../../components/ScenesList";
import {SourcesList} from "../../components/SourcesList";
import {useToastStore} from "../../store/useToastStore";

export const ConnectionObsDetails = () => {
    const {id} = useParams()
    const [connection, setConnection] = useState(null)
    const [selectedScene, setSelectedScene] = useState(null)
    const [sources, setSources] = useState([])
    const [syncLoading, setSyncLoading] = useState(false)
    const [archived, setArchived] = useState(false)

    const fetchConnectionObs = async () => {
        const data = await fetchConnectionById(id)
        setConnection(data)
        setSelectedScene(data.scenes[0])
        setSources(data.scenes?.[0]?.sources)
    }

    const synchronize = async () => {
        setSyncLoading(true)
        const sync = await synchronizeConnection(id)
        if (!sync.success) {
            useToastStore.getState().addToast("La synchronisation a échouée, la connexion à OBS n'est pas disponible", 'danger')
            console.error(sync.message)
        } else {
            await fetchConnectionObs()
            useToastStore.getState().addToast("Synchronisation terminée !", 'success')
        }
        setSyncLoading(false)
    }

    const handleSceneSelect = (scene) => {
        setSelectedScene(scene)
        setSources(scene.sources)
        setArchived(scene.archived)
    };

    useEffect(() => {
        fetchConnectionObs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div>
            <h1>Connexion OBS : {connection?.name}</h1>
            <button className={`btn btn-success`} disabled={syncLoading} onClick={synchronize}>{syncLoading ? "Synchronisation en cours…" : "Synchroniser"}</button>
            <hr/>
            {connection &&
                <div className="row">
                    <SourcesList
                        sources={sources}
                        setSources={setSources}
                        setConnection={setConnection}
                        archived={archived}
                    />
                    <ScenesList
                        scenes={connection.scenes}
                        selectedScene={selectedScene}
                        onSceneSelect={handleSceneSelect}
                    />
                </div>
            }

        </div>
    )
}