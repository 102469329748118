import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {fetchPlatforms, deletePlatform} from "../../services/Api/PlatformApi";

export const PlatformPage = () => {
    const [platforms, setPlatforms] = useState([])

    const getAllPlatforms = async () => {
        const data = await fetchPlatforms()
        setPlatforms(data)
    };

    const removePlatform = async (id) => {
        const confirmed = window.confirm("Sure ?")
        if (confirmed) {
            await deletePlatform(id)
            const data = await fetchPlatforms()
            setPlatforms(data)
        }
    };

    useEffect(() => {
        getAllPlatforms()
    }, []);

    return (
        <div>
            <h1>Plateformes ({platforms.length})</h1>
            <Link className="btn btn-success" to={`/platforms/new`}>Ajouter plateforme</Link>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>RTMP Url</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {platforms && platforms.length > 0 && (
                    platforms.map((platform, i) => (
                        <tr key={i}>
                            <td>{platform._id}</td>
                            <td>{platform.name}</td>
                            <td>{platform.rtmpUrl}</td>
                            <td>
                                <div className="btn-group">
                                    <Link className="btn btn-secondary"
                                          to={`/platforms/${platform._id}`}>Voir</Link>
                                    <Link className="btn btn-info"
                                          to={`/platforms/${platform._id}/edit`}>Modifier</Link>
                                    <button className="btn btn-danger"
                                            onClick={() => removePlatform(platform._id)}>Supprimer
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </table>
        </div>
    )
}