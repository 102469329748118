import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {createFirstSuperAdmin} from "../services/Api/UserApi";
import {checkReadyApp} from "../services/Api/LoginApi";

export const SuperAdminForm = () => {
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState({
        username: '',
        password: '',
        role: 'superadmin'
    });
    const [checker, setChecker] = useState('')

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setCurrentUser({...currentUser, [name]: value});
    };

    const addUser = async () => {
        await createFirstSuperAdmin({user: currentUser, checker: checker});
        navigate(`/`);
    };

    useEffect(() => {
        const checkSuperadmin = async () => {
            try {
                const data = await checkReadyApp()

                if (data.success) {
                    navigate('/');
                }
            } catch (err) {
                console.error('Error:', err);
            }
        };
        checkSuperadmin();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <h2>Formulaire d'utilisateur</h2>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="Username">Username</label>
                <div className="col-sm-10">
                    <input
                        className="form-control"
                        type="text"
                        name="username"
                        value={currentUser.username}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="password">Password</label>
                <div className="col-sm-10">
                    <input
                        className="form-control"
                        type="password"
                        name="password"
                        value={currentUser.password}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="checker">Checker</label>
                <div className="col-sm-10">
                    <input
                        className="form-control"
                        type="text"
                        name="text"
                        value={checker}
                        onChange={(e) => setChecker(e.target.value)}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <div className="btn-group" role="group">
                    <button
                        className="btn btn-success"
                        onClick={addUser}
                    >
                        Valider
                    </button>
                </div>
            </div>
        </div>
    );
};
