import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAuth} from "../hooks/useAuth";
import {checkReadyApp, postLogin} from "../services/Api/LoginApi";

export const Home = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const {isAuthenticated} = useAuth();
    const {login} = useAuth();

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/platforms');
        } else {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        const checkSuperadmin = async () => {
            try {
                const data = await checkReadyApp();

                if (!data.success) {
                    navigate('/create-superadmin');
                }
            } catch (err) {
                console.error('Error:', err);
            }
        };
        checkSuperadmin();
    }, [navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const data = await postLogin(username, password)
            if (data.token) {
                login(navigate, data.token);
            } else {
                setError(data);
            }
        } catch (err) {
            console.error('Error:', err);
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <div className="container">
            <h2>Login</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label htmlFor="username" className="form-label">Username</label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">Login</button>
            </form>
        </div>
    );
};
