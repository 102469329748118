import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {fetchConnectionOBS, deleteConnection} from "../../services/Api/ConnectionOBSApi";

export const ConnectionObsPage = () => {
    const [connections, setConnections] = useState([])

    const getAllConnections = async () => {
        const data = await fetchConnectionOBS()
        setConnections(data)
    };

    const removeConnection = async (id) => {
        const confirmed = window.confirm("Sure ?")
        if (confirmed) {
            await deleteConnection(id)
            const data = await fetchConnectionOBS()
            setConnections(data)
        }
    };

    useEffect(() => {
        getAllConnections()
    }, []);

    return (
        <div>
            <h1>Connexions OBS ({connections.length})</h1>
            <Link className="btn btn-success" to={`/connections/new`}>Ajouter connexion</Link>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>IP</th>
                    <th>Port</th>
                    <th>Password</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {connections && connections.length > 0 && (
                    connections.map((connection, i) => (
                        <tr key={i}>
                            <td>{connection._id}</td>
                            <td>{connection.name}</td>
                            <td>{connection.ipAddress}</td>
                            <td>{connection.port}</td>
                            <td>{connection.password}</td>
                            <td>
                                <div className="btn-group">
                                    <Link className="btn btn-secondary"
                                          to={`/connections/${connection._id}`}>Voir</Link>
                                    <Link className="btn btn-info"
                                          to={`/connections/${connection._id}/edit`}>Modifier</Link>
                                    <button className="btn btn-danger"
                                            onClick={() => removeConnection(connection._id)}>Supprimer
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </table>
        </div>
    )
}