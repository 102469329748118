import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {createTrigger, deleteTrigger, fetchTriggerById, updateTrigger} from "../../services/Api/TriggerApi";
import {fetchPlatforms} from "../../services/Api/PlatformApi";

export const TriggerForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [currentTrigger, setCurrentTrigger] = useState({
        platform: '',
        name: '',
        cost: '',
        direct: ''
    });
    const [platforms, setPlatforms] = useState([]);

    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;
        setCurrentTrigger({...currentTrigger, [name]: inputValue});
    };

    const addTrigger = async () => {
        await createTrigger(currentTrigger);
        navigate(`/triggers`);
    };

    const removeTrigger = async () => {
        const confirmed = window.confirm("Êtes-vous sûr ?");
        if (confirmed) {
            await deleteTrigger(id);
            navigate(`/triggers`);
        }
    };

    const editTrigger = async (currentTrigger) => {
        await updateTrigger(id, currentTrigger);
        navigate(`/triggers`);
    };

    const fetchTrigger = async (id) => {
        const data = await fetchTriggerById(id);
        setCurrentTrigger(data);
    };

    const fetchAllPlatforms = async () => {
        const platforms = await fetchPlatforms();
        setPlatforms(platforms);
    };

    useEffect(() => {
        fetchAllPlatforms();
        if (id !== undefined) {
            fetchTrigger(id);
        } else {
            setCurrentTrigger({
                platform: '',
                name: '',
                cost: '',
                direct: ''
            });
        }
    }, [id]);

    return (
        <div>
            <h2>Formulaire de chaîne</h2>
            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="platform">Plateforme</label>
                <div className="col-sm-10">
                    <select
                        className="form-control"
                        name="platform"
                        value={currentTrigger.platform._id}
                        onChange={handleInputChange}
                    >
                        <option value="">Sélectionnez une plateforme</option>
                        {platforms.map((platform) => (
                            <option key={platform._id} value={platform._id}>
                                {platform.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="name">Nom du déclencheur</label>
                <div className="col-sm-10">
                    <input
                        className="form-control"
                        type="text"
                        name="name"
                        value={currentTrigger.name}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="urlStream">Coût en Token</label>
                <div className="col-sm-10">
                    <input
                        className="form-control"
                        type="number"
                        name="cost"
                        value={currentTrigger.cost}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="direct">Direct ?</label>
                <div className="col-sm-10">
                    <input
                        name="direct"
                        className="form-check-input"
                        type="checkbox"
                        checked={currentTrigger.direct}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <div className="btn-group" role="group">
                    <button
                        className="btn btn-success"
                        onClick={() => id ? editTrigger(currentTrigger) : addTrigger()}
                    >
                        Valider
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={removeTrigger}
                        disabled={!currentTrigger._id}
                    >
                        Supprimer
                    </button>
                </div>
            </div>
        </div>
    );
};
