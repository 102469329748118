import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {fetchTriggers, deleteTrigger} from "../../services/Api/TriggerApi";

export const TriggerPage = () => {
    const [triggers, setTriggers] = useState([])

    const getAllTriggers = async () => {
        const data = await fetchTriggers()
        setTriggers(data)
    };

    const removeTrigger = async (id) => {
        const confirmed = window.confirm("Sure ?")
        if (confirmed) {
            await deleteTrigger(id)
            const data = await fetchTriggers()
            setTriggers(data)
        }
    };

    useEffect(() => {
        getAllTriggers()
    }, []);

    return (
        <div>
            <h1>Déclencheurs ({triggers.length})</h1>
            <Link className="btn btn-success" to={`/triggers/new`}>Ajouter déclencheur</Link>
            <table className="table table-striped">
                <thead>
                <tr>
                    <th>Id</th>
                    <th>Platform</th>
                    <th>Name</th>
                    <th>Cost Token</th>
                    <th>Direct</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {triggers && triggers.length > 0 && (
                    triggers.map((trigger, i) => (
                        <tr key={i}>
                            <td>{trigger._id}</td>
                            <td>{trigger.platform.name}</td>
                            <td>{trigger.name}</td>
                            <td>{trigger.cost}</td>
                            <td>{trigger.direct ? 'Direct' : 'Indirect'}</td>
                            <td>
                                <div className="btn-group">
                                    <Link className="btn btn-secondary"
                                          to={`/triggers/${trigger._id}`}>Voir</Link>
                                    <Link className="btn btn-info"
                                          to={`/triggers/${trigger._id}/edit`}>Modifier</Link>
                                    <button className="btn btn-danger"
                                            onClick={() => removeTrigger(trigger._id)}>Supprimer
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))
                )}
                </tbody>
            </table>
        </div>
    )
}