import {fetchWithAuth} from "../utils/Api";

export const fetchStreamings = async () => {
    try {
        const response = await fetchWithAuth(`/streamings`)
        return await response.json()
    } catch (error) {
        console.error('Error fetching streamings :', error)
        throw error
    }
}

export const fetchStreamingById = async (id) => {
    try {
        const response = await fetchWithAuth(`/streamings/${id}`)
        return await response.json()
    } catch (error) {
        console.error(`Error fetching streaming with ID ${id}:`, error)
        throw error
    }
}

export const createStreaming = async (streamingData) => {
    try {
        const response = await fetchWithAuth(`/streamings`, {
            method: 'POST',
            body: JSON.stringify(streamingData),
        })
        return await response.json()
    } catch (error) {
        console.error('Error creating connexion:', error)
        throw error
    }
}

export const deleteStreaming = async (id) => {
    try {
        const response = await fetchWithAuth(`/streamings/${id}`, {method: 'DELETE'})
        return await response.json()
    } catch (error) {
        console.error(`Error deleting streaming with ID ${id}:`, error)
        throw error
    }
}

export const startStreaming = async (id) => {
    try {
        const response = await fetchWithAuth(`/streamings/start/${id}`)
        return await response.json()
    } catch (error) {
        console.error(`Error deleting streaming with ID ${id}:`, error)
        throw error
    }
}

export const stopStreaming = async (id, tokens) => {
    try {
        const response = await fetchWithAuth(`/streamings/stop/${id}/${tokens}`)
        return await response.json()
    } catch (error) {
        console.error(`Error deleting streaming with ID ${id}:`, error)
        throw error
    }
}